

function validationOfCheckBoxFields(value, validations) {

  for (let validation of validations) {

    console.log({ validation: validation.type, value})

    if (validation.type == "and") {
      let rtn = (validationOfCheckBoxFields(value, validation.left) && validationOfCheckBoxFields(value, validation.right))
      if (rtn) {
        console.log("And matched")
        return true
      }
      continue
    }

    if (validation.type == "or") {
      let rtn =  (validationOfCheckBoxFields(value, validation.left) || validationOfCheckBoxFields(value, validation.right))
      if (rtn) {
        console.log("OR matched")
        return true
      }
      continue
    }



    if (validation.type == "only") {
      if (!Array.isArray(validation.value)) {
        return false
      }
      if (!Array.isArray(value)) {
        return false
      }
      let difference = validation.value
                 .filter(x => !value.includes(x))
                 .concat(value.filter(x => !validation.value.includes(x)));
      let rtn =  (difference.length == 0)
      if (rtn) {
        console.log("Only matched")
        return true
      }
      continue
    }

    let intersection = validation.value.filter(x => value.includes(x));

    if (validation.type == "any") {
      let rtn =  (intersection.length > 0)
      if (rtn) {
        console.log("Any matched")
        return true
      }
      continue
    }

    if (validation.type == "not") {
      let rtn =  (intersection.length == 0)
      if (rtn) {
        console.log("Not matched")
        return true
      }
      continue
    }



  }

  console.log("Nothing matched")
  return false

}



export { validationOfCheckBoxFields}