<template>
  <div
    class="container has-background-white-ter has-text-centered pt-3"
    style="max-height: 100%"
  >
    <figure class="image container is-128x128" v-if="myForm?.domain">
      <img
        :src="'https://assets.sense.services/' + myForm?.domain + '/logo.svg'"
      />
    </figure>
    <h1 class="font:bold font:20">{{ myForm?.formTitle }}</h1>

    <section
      v-if="myCurrentSection == $getConst('FORMS_HUMAN_CHECK')"
      class="section is-primary pb-1"
      style="font-size: large; max-height: 100%"
    >
      <div ref="turnstileBox" id="turnstile-box"></div>
      <div class="scroller pb:4em" style="max-height: 100%">
        <p>{{ introduction }}</p>
      </div>
    </section>

    <section
      v-else-if="myCurrentSection == $getConst('FORMS_SUBMIT')"
      class="section is-primary pb-1"
    >
      <p>If you wish to complete the process, please use the button below.</p>

      <nav class="level">
        <div class="level-item margin-top:1em">
          <button
            class="button"
            @click="myCurrentSection = $getConst('FORMS_FIRST_SECTION')"
          >
            Review
          </button>
          <button class="button" @click="submitForm()" :disabled="submitting">
            Submit
          </button>
        </div>
      </nav>
    </section>

    <section
      v-else-if="myCurrentSection == $getConst('FORMS_SUBMITTED')"
      class="section is-primary pb-1"
    >
      <p>{{ myForm.thankYouMessage }}</p>
      <p class="padding-top:1em">
        Your reference: <strong>{{ submissionReference }}</strong>
      </p>
    </section>

    <section
      v-else
      class="section is-primary pb-1 scroller"
      style="font-size: large"
    >
      <div v-if="myForm" class="pb:4em">
        <progress
          class="progress is-large is-primary"
          :value="myCurrentSection + 1"
          :max="total"
        >
          {{ myCurrentSection + 1 }} of {{ total }}%
        </progress>

        <div
          v-if="group.richText"
          v-html="expand(group.richText.content)"
        ></div>
        <div v-for="(f, ii) in group.fields" :key="ii">
          <formField
            :fieldData="f"
            :value="myValues[f.name]"
            :previousFields="myValues"
            :index="myCurrentSection"
            @myValue="newValue"
          />

          <div v-for="(x, y) in f.dependentFieldFilters" :key="y">
            <formField
              :fieldData="x.dependentFormField"
              :value="myValues[x.dependentFormField.name]"
              :previousFields="myValues"
              :index="myCurrentSection"
              @myValue="newValue"
              v-if="showDependentField(f.name, x, myCurrentSection)"
              >{{ x }}</formField
            >
          </div>
        </div>
      </div>
    </section>

    <nav
      class="navbar is-fixed-bottom is-info"
      v-if="iAmHuman && myCurrentSection == $getConst('FORMS_HUMAN_CHECK')"
      role="navigation"
      aria-label="form navigation"
    >
      <div
        class="navbar-menu is-active is-info"
        style="display: block; background-color: #3e8ed0; color: #fff"
      >
        <div
          class="navbar-start"
          style="text-align: center; justify-content: center"
        >
          <div class="navbar-item" style="text-align: center" v-if="isARestart">
            <button @click="restart()" class="button">Restart</button>
            <button @click="continueForm()" class="button">Continue</button>
          </div>

          <div class="navbar-item" style="text-align: center" v-else>
            <button
              @click="myCurrentSection = $getConst('FORMS_FIRST_SECTION')"
              class="button"
            >
              Start
            </button>
          </div>
        </div>

        <div class="navbar-end"></div>
      </div>
    </nav>
    <nav
      v-else-if="iAmHuman && myCurrentSection != $getConst('FORMS_HUMAN_CHECK')"
      class="navbar is-fixed-bottom is-info"
      role="navigation"
      aria-label="form navigation"
    >
      <div
        class="navbar-menu is-active is-info"
        style="display: block; background-color: #3e8ed0; color: #fff"
      >
        <div
          class="navbar-start"
          style="text-align: center; justify-content: center"
        >
          <div class="navbar-item" style="text-align: center">
            <button
              @click="previousSection()"
              :disabled="myCurrentSection == $getConst('FORMS_FIRST_SECTION')"
              class="button"
            >
              &lt; Previous
            </button>

            <button
              @click="nextSection()"
              :disabled="!sectionOK"
              class="button"
            >
              Next
            </button>
          </div>
        </div>

        <div class="navbar-end"></div>
      </div>
    </nav>
  </div>
</template>

<script>
//import formSchema from "@/forms/registration.js"
import formField from "@/components/form/field.vue";
import { toast } from "bulma-toast";
import functions from "helpers/functions.js";

const ls = require("local-storage");

//

export default {
  name: "xyz",

  data() {
    return {
      myForm: null,
      myValues: {},
      mySectionsOk: {},
      mySectionsFieldsOk: {},
      myCurrentSection: this.$getConst("FORMS_HUMAN_CHECK"),
      loading: false,
      savedValues: {},
      iAmHuman: false,
      isARestart: false,
      formID: null,
      digest: null,
      submitting: false,
      submissionReference: null,
      introduction: "",
    };
  },
  async mounted() {
    this.formID = this.$route.params.formID;

    this.savedValues = ls("form-" + this.formID) ?? {};
    this.introduction = "";

    this.isARestart = Object.prototype.hasOwnProperty.call(
      this.savedValues,
      "myCurrentSection"
    );

    console.log({ restart: this.isARestart });

    this.$api.get("public/form/" + this.formID).then((myData) => {
      this.myForm = myData.form;
      this.digest = myData.digest;
      this.submissionReference = null;
      this.introduction = this.myForm.introduction;

      this.mySectionsOk = Array(this.myForm.formFieldGroups.length)
        .keys()
        .reduce((p, c) => {
          p[c.toString()] = false;
          return p;
        }, {});

      this.myValues = this.savedValues?.myValues || {};
      this.mySectionsFieldsOk = {};
      this.applySavedValues();
    });

    if (this.$store.getters.isAuthenticatedUser) {
      if (this.isARestart) {
        toast({
          message:
            "You have previously started to complete this form. You can choose to continue the submission or restart",
          type: "is-success",
          duration: 5000,
          closeOnClick: true,
          position: "bottom-center",
        });
      }
      this.iAmHuman = true;
      return;
    }

    let xKey = this.$cookies.get("isAHuman");

    if (xKey) {
      console.log("I have an xKey of " + xKey);
      if (this.isARestart) {
        toast({
          message:
            "You have previously started to complete this form. You can choose to continue the submission or restart",
          type: "is-success",
          duration: 5000,
          closeOnClick: true,
          position: "bottom-center",
        });
      }
      this.iAmHuman = true;
      return;
    }

    if (window.turnstile === null || !window.turnstile) {
      const script = document.createElement("script");
      script.src =
        "https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
    this.renderTurnstile();
  },
  created() {},
  components: {
    formField,
  },
  computed: {
    group() {
      return this.myForm?.formFieldGroups?.[this.myCurrentSection] ?? [];
    },
    sectionOK() {
      console.log(
        "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
      );
      console.log({
        section: this.myCurrentSection,
        value: this.mySectionsOk[this.myCurrentSection.toString()],
      });
      return this.mySectionsOk[this.myCurrentSection.toString()];
    },
    total() {
      return this.myForm.formFieldGroups.length;
    },
  },
  methods: {
    expand(text) {
      let x = functions.expand(text, this.myValues);
      console.log({ text, x });
      return x;
    },

    submitForm() {
      this.submitting = true;

      let data = {
        formID: this.formID,
        values: this.myValues,
        digest: this.digest,
      };

      let x = null;

      if (this.$store.getters.isAuthenticatedUser) {
        x = this.$api.post("v1/form/", true, false, data);
      } else {
        data["iamhuman"] = this.$cookies.get("isAHuman");
        x = this.$api.post("public/form/", false, false, data);
      }

      x.then((data) => {
        if (data.status === "success") {
          this.submissionReference = data.reference;
          this.myCurrentSection = this.$getConst("FORMS_SUBMITTED");
          ls.clear("form-" + this.formID);
        } else {
          alert("Sorry, something went wrong");
        }
      });
    },

    applySavedValues() {
      let seenFields = Object.assign({}, this.myValues);

      const that = this;

      this.myForm.formFieldGroups.forEach((x, i) => {
        x.fields.forEach((y) => {
          that.$set(that.myValues, y.name, that.myValues[y.name] ?? null);

          if (!that.mySectionsFieldsOk[i]) {
            that.mySectionsFieldsOk[i] = {};
          }

          delete seenFields[y.name];

          if (!y.required) {
            that.$set(that.mySectionsFieldsOk[i], y.name, true);
            return;
          }

          if (Object.prototype.hasOwnProperty.call(that.myValues, y.name)) {
            that.$set(that.mySectionsFieldsOk[i], y.name, true);
          } else {
            that.$set(
              that.mySectionsFieldsOk[i],
              y.name,
              that.mySectionsFieldsOk[i][y.name] ?? false
            );
          }
        });
        this.checkValidity(i);
      });

      Object.entries(seenFields).forEach((x) => {
        this.$set(this.myValues, x[0], x[1]);
      });
    },

    continueForm() {
      if (this.digest !== this.savedValues.myDigest) {
        toast({
          message:
            "The form has been modified since you last worked on it, please review before continuing",
          type: "is-info",
          duration: 5000,
          closeOnClick: true,
          position: "bottom-center",
        });
        this.myCurrentSection = this.$getConst("FORMS_FIRST_SECTION");
        return;
      }
      this.myCurrentSection = this.savedValues.myCurrentSection;
    },

    restart() {
      this.mySectionsOk = Array(this.myForm.formFieldGroups.length).fill(
        false,
        0
      );
      this.myValues = {};
      this.mySectionsFieldsOk = {};

      this.savedValues = {};

      ls.clear("form-" + this.formID);

      this.applySavedValues();
    },

    renderTurnstile() {
      const that = this;
      window.onloadTurnstileCallback = () => {
        window.turnstile?.render("#turnstile-box", {
          sitekey: process.env.VUE_APP_TURNSTILE,
          callback: async (response) => {
            let details = await that.$api.post(
              "public/turnstile/",
              false,
              false,
              { "cf-turnstile-response": response }
            );

            if (details.key) {
              that.iAmHuman = true;

              that.$cookies.set("isAHuman", details.key, details.ttl);

              if (this.isARestart) {
                toast({
                  message:
                    "You have previously started to complete this form. You can choose to continue the submission or restart",
                  type: "is-success",
                  duration: 5000,
                  closeOnClick: true,
                  position: "bottom-center",
                });
              }
            }
          },
          "expired-callback": () => {
            that.$emit("expire");
            console.log("expire");
          },
          "error-callback": () => {
            that.$emit("fail");
            console.log("fail");
          },
        });
      };
    },

    nextSection() {
      console.log("Moving to the next section", this.myCurrentSection);

      if (this.myForm?.formFieldGroups?.[this.myCurrentSection + 1]) {
        this.myCurrentSection = this.myCurrentSection + 1;
        console.log("Moved to the next section", this.myCurrentSection);

        ls("form-" + this.formID, {
          myDigest: this.digest,
          mySectionsOk: this.mySectionsOk,
          myValues: this.myValues,
          mySectionsFieldsOk: this.mySectionsFieldsOk,
          myCurrentSection: this.myCurrentSection,
        });
        return;
      }

      this.myCurrentSection = this.$getConst("FORMS_SUBMIT");
    },

    previousSection() {
      console.log("Moving to the previous section", this.myCurrentSection);

      if (this.myCurrentSection == this.$getConst("FORMS_SUBMIT")) {
        this.myCurrentSection = this.myForm.formFieldGroups.length - 1;
        return;
      }

      if (this.myCurrentSection < 1) {
        return;
      }

      console.log(this.myValues);

      if (this.myForm?.formFieldGroups?.[this.myCurrentSection - 1]) {
        this.myCurrentSection = this.myCurrentSection - 1;
        console.log("Moved to the previous section", this.myCurrentSection);

        ls("form-" + this.formID, {
          myDigest: this.digest,
          mySectionsOk: this.mySectionsOk,
          myValues: this.myValues,
          mySectionsFieldsOk: this.mySectionsFieldsOk,
          myCurrentSection: this.myCurrentSection,
        });
        return;
      }
    },
    newValue(myValue) {
      console.log({ msg: "In form new value", myValue });

      this.$set(this.myValues, myValue.identifier, myValue.value);

      if (!myValue.ok) {
        console.log("I am not ok");
        if (
          this.mySectionsFieldsOk[myValue.index][myValue.identifier] ??
          true
        ) {
          this.mySectionsFieldsOk[myValue.index][myValue.identifier] = false;
        }
        if (this.mySectionsOk[myValue.index.toString()]) {
          this.mySectionsOk[myValue.index.toString()] = false;
        }
        return;
      }

      console.log({ msg: "I am ok....", myValue });

      this.clearFieldError(myValue.index, myValue.identifier, true);

      console.log({ myValue, ok: this.mySectionsOk });
    },

    checkValidity(index) {
      console.log("In checkValidity with ", index);

      for (const [key, value] of Object.entries(
        this.mySectionsFieldsOk[index] ?? {}
      )) {
        if (value == false) {
          console.log({ key, is: "missing" });
          if (this.mySectionsOk[index.toString()]) {
            this.$set(this.mySectionsOk, index, false);
          }
          return;
        }
        delete this.mySectionsFieldsOk[index][key];
      }

      if (!this.mySectionsOk[index.toString()]) {
        this.$set(this.mySectionsOk, index, true);
      }
    },

    clearFieldError(index, key, refresh = false) {
      console.log({
        msg: "cfe",
        index,
        key,
        refresh,
        msof: this.mySectionsFieldsOk[index],
      });

      if (!this.mySectionsFieldsOk[index]) {
        if (refresh) {
          this.checkValidity(index);
        }
        return;
      }

      if (this.mySectionsFieldsOk[index][key] ?? true) {
        if (refresh) {
          this.checkValidity(index);
        }
        return;
      }

      delete this.mySectionsFieldsOk[index][key];

      if (refresh) {
        this.checkValidity(index);
      }
    },

    showDependentField(currentField, dependentField, index) {
      console.log({
        msg: " sdf",
        currentField,
        dependentField,
        myValues: this.myValues,
      });

      console.log([currentField, dependentField, this.myValues]);

      let myValue = this.myValues[currentField];

      for (let filter of dependentField.filters) {
        if (filter.operator != "SET_ANY") {
          this.clearFieldError(
            index,
            dependentField.dependentFormField.name,
            true
          );
          return false;
        }

        if (Array.isArray(myValue) === false) {
          myValue = [myValue];
        }

        for (let j of myValue) {
          if (filter.strValue === j) {
            return true;
          }
          if (filter.boolValue === j) {
            return true;
          }

          if (filter.numberValue === j) {
            return true;
          }

          if (filter.strValues.includes(j)) {
            return true;
          }

          if (filter.numberValues.includes(j)) {
            return true;
          }
        }
      }

      //console.log({ currentField, currentValue: this.myValues[currentField], dependentField })
      this.clearFieldError(index, dependentField.dependentFormField.name, true);
      return false;
    },
  },
};
</script>

<style scoped>
.scroller::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroller {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
