<template>
  <div class="field">
    <label class="label">{{ expand(myFieldData.label) }}</label>
    <div v-if="myFieldData.fieldType == 'text'" class="control">
      <input
        :required="myFieldData.required"
        class="input"
        :class="isRequiredFieldClass()"
        type="text"
        v-model.lazy.trim="myValue"
        :placeholder="myFieldData.placeholder"
      />
    </div>

    <div v-else-if="myFieldData.fieldType == 'date'" class="control">
      <input
        :required="myFieldData.required"
        class="input"
        :class="isRequiredFieldClass()"
        type="date"
        v-model.lazy.trim="myValue"
        :placeholder="myFieldData.placeholder"
      />
    </div>
    <vue-phone-number-input
      v-else-if="myFieldData.fieldType == 'phone'"
      default-country-code="GB"
      :required="myFieldData.required"
      v-model="myValue"
    />
    <div v-else-if="myFieldData.fieldType == 'email'" class="control">
      <input
        :required="myFieldData.required"
        class="input"
        :class="isRequiredFieldClass()"
        type="email"
        v-model.lazy.trim="myValue"
        :placeholder="myFieldData.placeholder"
      />
    </div>
    <div v-else-if="myFieldData.fieldType == 'select'" class="control">
      <div class="select" :class="isRequiredFieldClass()">
        <select :required="myFieldData.required" v-model.lazy.trim="myValue">
          <option
            v-for="(opt, jj) in myFieldData.options"
            :key="jj"
            :value="opt.value"
          >
            {{ opt.label }}
          </option>
        </select>
      </div>
    </div>
    <div
      v-else-if="myFieldData.fieldType == 'checkbox'"
      class="control ul-box"
      :class="isRequiredFieldClass()"
    >
      <ul :required="myFieldData.required">
        <li v-for="(opt, jj) in myFieldData.options" :key="jj">
          <label class="checkbox">
            <input
              type="checkbox"
              :value="opt.value"
              v-model.lazy.trim="myValue"
            />
            {{ opt.label }}
          </label>
        </li>
      </ul>
    </div>

    <div v-else-if="myFieldData.fieldType == 'textarea'" class="control">
      <textarea
        class="textarea"
        :class="isRequiredFieldClass()"
        :required="myFieldData.required"
        :placeholder="myFieldData.placeholder"
        v-model.trim="myValue"
      ></textarea>
    </div>
    <div
      v-else-if="myFieldData.fieldType == 'displayText'"
      :class="isRequiredFieldClass()"
    >
      <p>{{ expand(myFieldData.displayText) }}</p>
    </div>
    <div v-else>
      <p>{{ myFieldData }}</p>
    </div>
    <p class="help" v-if="myFieldData.description">
      {{ expand(myFieldData.description) }}
    </p>

    <div v-if="myFieldData.links?.length" style="padding-top: 4px">
      <p class="is-1" style="font-weight: bold; font-size: medium">
        External Links
      </p>
      <ul>
        <li
          v-for="l in myFieldData.links"
          :key="l.link"
          style="margin-left: 2em"
        >
          <a :href="l.link" target="_blank">{{ l.name || "External Link" }}</a>
        </li>
      </ul>
    </div>
    <p class="help is-danger" style="(!errorMsg)?'visibility: hidden;': ''">
      {{ errorMsg }}&nbsp;
    </p>
  </div>
</template>
<script>
import validator from "validator";
import { validationOfCheckBoxFields } from "@/helpers/fielddefs.js";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

/*
isEmail,
  isURL,
  isMACAddress,
  isIP,
  isIPRange,
  isFQDN,
  isBoolean,
  isIBAN,
  isBIC,
  isAlpha,
  isAlphaLocales,
  isAlphanumeric,
  isAlphanumericLocales,
  isNumeric,
  isPassportNumber,
  isPort,
  isLowercase,
  isUppercase,
  isAscii,
  isFullWidth,
  isHalfWidth,
  isVariableWidth,
  isMultibyte,
  isSemVer,
  isSurrogatePair,
  isInt,
  isIMEI,
  isFloat,
  isFloatLocales,
  isDecimal,
  isHexadecimal,
  isOctal,
  isDivisibleBy,
  isHexColor,
  isRgbColor,
  isHSL,
  isISRC,
  isMD5,
  isHash,
  isJWT,
  isJSON,
  isEmpty,
  isLength,
  isLocale,
  isByteLength,
  isUUID,
  isMongoId,
  isAfter,
  isBefore,
  isIn,
  isLuhnValid,
  isCreditCard,
  isIdentityCard,
  isEAN,
  isISIN,
  isISBN,
  isISSN,
  isMobilePhone,
  isMobilePhoneLocales,
  isPostalCode,
  isPostalCodeLocales,
  isEthereumAddress,
  isCurrency,
  isBtcAddress,
  isISO6391,
  isISO8601,
  isRFC3339,
  isISO31661Alpha2,
  isISO31661Alpha3,
  isISO4217,
  isBase32,
  isBase58,
  isBase64,
  isDataURI,
  isMagnetURI,
  isMimeType,
  isLatLong,
  isSlug,
  isStrongPassword,
  isTaxID,
  isDate,
  isLicensePlate,
  isVAT,
  ibanLocales,
*/

import functions from "helpers/functions.js";

export default {
  name: "formField",
  props: ["fieldData", "index", "value", "previousFields"],

  components: {
    VuePhoneNumberInput,
  },

  data: function () {
    return {
      myValue: this.value,
      errorMsg: false,
      avoid_changes: 0,
      myFieldData: this.fieldData,
    };
  },

  computed: {},

  methods: {
    expand(text) {
      return functions.expand(text, this.previousFields);
    },

    // ?  : ''
    isRequiredFieldClass() {
      if (this.myFieldData.fieldType == "displayText") {
        this.errorMsg = null;
        return "is-success";
      }

      if (this.myFieldData.fieldType == "checkbox") {
        if (this.myFieldData.validations?.length) {
          if (
            validationOfCheckBoxFields(
              this.myValue,
              this.myFieldData.validations
            )
          ) {
            this.errorMsg = null;
            return "is-success";
          }
          this.errorMsg = "Please select a valid combination";
          return "is-danger";
        }

        let y = this.myFieldData.requiredNumberOfOptions ?? 1;
        let num = this.myValue?.length ?? 0;

        if (num < y) {
          this.errorMsg =
            "Please select " + y.toString() + " option" + (y > 1 ? "s" : "");
          return "is-danger";
        }

        this.errorMsg = null;
        return "is-success";
      }

      if (this.myFieldData.validations?.length) {
        if (!this.myValue) {
          this.errorMsg = "Please provide a value";
          return "is-danger";
        }

        for (let validation of this.myFieldData.validations) {
          let ok = validation.params
            ? validator[validation.type](this.myValue, validation.params)
            : validator[validation.type](this.myValue);

          console.log({ validation, ok, val: this.myValue });
          if (!ok) {
            this.errorMsg = validation.message ?? "Please provide a value";
            return "is-danger";
          }
        }
      }

      console.log({
        required: this.myFieldData.required,
        index: this.index,
        value: this.myValue,
      });

      if (!this.myFieldData.required || this.myValue) {
        this.errorMsg = null;
        return "is-success";
      }

      this.errorMsg = "Please provide a value";
      return "is-danger";
    },
  },

  watch: {
    myValue(newValue, _oldValue) {
      if (this.avoid_changes > 0) {
        this.avoid_changes -= 1;
        console.log(["unsupressing change,", this.avoid_changes]);
        return;
      }

      this.$nextTick(() => {
        this.$emit("myValue", {
          identifier: this.myFieldData.name,
          value: newValue,
          index: this.index,
          from: "change",
          ok: this.errorMsg === null,
        });
      });
    },
    errorMsg(newValue, _oldValue) {
      this.$emit("myValue", {
        identifier: this.myFieldData.name,
        value: this.myValue,
        index: this.index,
        from: "error",
        ok: newValue === null,
      });
    },

    $props: {
      handler(newValue, _oldValue) {
        this.myFieldData = newValue.fieldData;

        console.log({ msg: "in field change props", mfd: this.myFieldData });

        if (newValue.fieldData?.fieldType !== "checkbox") {
          this.myValue = newValue.value;
          return;
        }

        console.log([
          "Detected a checkbox in changed",
          typeof newValue.value,
          newValue.value,
        ]);

        let x = newValue.value;

        console.log("Suppressing change....");

        if (!Array.isArray(x)) {
          console.log(["Before change: ", x]);
          x = [x];
          console.log(["After change: ", x]);
        }

        console.log("Suppressing change....");

        this.avoid_changes += 1;
        this.myValue = x.filter((a) => a);
        console.log(this.myValue);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
div.ul-box {
  float: none;
  border: thin;
  border-style: solid;
  padding: 5px;
}

div.ul-box.is-danger {
  border-color: red;
}

div.ul-box.is-success {
  border-color: green;
}
</style>
